import { Component } from "react";
import { AuthConsumer } from "../../authentication/context";
import {
  Stack,
  Avatar,
  Autocomplete,
  TextField,
  Switch,
  Typography,
  Button,
} from "@mui/material";

import {
  createpermissionforfolderid,
  getDealers,
  getalladminfolders,
  listpermissionforfolderid,
  updateDealers,
  updateDealersstatus,
} from "../../controllers/dealer.controller";
import TableGrid from "../users/components/TableGrid";
import { getAllUsers } from "../../controllers/users.controller";
import { Link } from "react-router-dom";
import Navbar from "../../NavBar/Navbar";

type props = {
  user: { email: string; accesstype: string; access_token: string };
  params: { uid: string; base64: string };
  navigate: any;
};

interface DealerInterface {
  approvedby: string;
  id: string;
  uid: string;
  name: string;
  email: string;
  image: string;
  phone: string;
  address: string;
  devices: string[];
  access_token: string;
  status: string;
}

const pages = ["Home", "Dealers"];
const settings = ["Logout"];

class Dealercomponent extends Component {
  props: props;
  state: Readonly<{
    user: DealerInterface;
    view: "loading" | "ok" | "error";
    updateBtn: boolean;
    ischecked: boolean;
    users: any;
    loading: boolean;
    availableusersarray: string[];
  }>;
  constructor(props: props) {
    super(props);
    this.props = props;
    this.state = {
      updateBtn: false,
      view: "loading",
      user: {
        access_token: "",
        uid: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        image: "",
        id: "",
        devices: [],
        approvedby: "null",
        status: "pending...",
      },
      availableusersarray: [],
      ischecked: false,
      users: {},
      loading: true,
    };
  }

  handleChange = (event: any) => {
    const { ischecked, user } = this.state;
    var {
      user: { email },
    } = this.props;

    console.log(event.target.checked, email);

    if (event.target.checked === true) {
      user.status = "Approved";
    } else {
      user.status = "pending...";
    }
    this.setState({ updateBtn: true });

    updateDealersstatus(email, user.status, user.uid)
      .then(({ data }) => {
        console.log(data);

        if (data.status === 200) {
          this.setState({
            updateBtn: false,
            ischecked: event.target.checked,
            user: user,
          });

          alert("Updated");
        } else {
          console.log(data);
          this.setState({ updateBtn: false, view: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ updateBtn: false, view: "error" });
      });
  };

  componentDidMount(): void {
    var {
      user: { email, accesstype, access_token },
      params: { uid, base64 },
    } = this.props;
    var { users, availableusersarray } = this.state;
    var decoded = atob(base64).toString();
    var objUser = JSON.parse(decoded);
    console.log(objUser,this.props.params.uid);

   
    getDealers(this.props.params.uid)
      .then(({ data }) => {
        console.log(data);

        if (data.status === 200) {
          var sliceddata = data.users.find((e:any)=>e[0]==this.props.params.uid).slice(6);
          var devices = sliceddata.filter((device: any) => device !== "");
          console.log("surya",devices);
          
          this.setState({ user: { ...objUser, devices }, view: "ok" });
          
          getAllUsers(objUser.email, "dealers")
            .then(({ data }) => {
              var data = data.data.users;
              console.log(data);
              availableusersarray = [];
              var users = data.map((user: any, id: number) => {
                var finaldata = {
                  uid: user.uid,
                  name: user.name,
                  email: user.mail,
                  phone: user.mobile,
                  address: user.address || "",
                  image: user.photo || "",
                  id: id,
                };
                availableusersarray.push(user.uid);

                return finaldata;
              });
              console.log(users, access_token);

              getalladminfolders(access_token)
                .then((res: any) => {
                  var folderslist = res.data.files;
                  console.log(
                    "allfolders data",
                    res,
                    folderslist,
                    availableusersarray
                  );

                  var arrayofaccountfolders: any[] = [];

                  if (availableusersarray.length > 0) {
                    folderslist.forEach((ele: any) => {
                      availableusersarray.map((es) =>
                        es === ele.name.replace("log_", "")
                          ? arrayofaccountfolders.push(ele)
                          : null
                      );
                    });

                    console.log(arrayofaccountfolders);
                    arrayofaccountfolders.forEach(
                      async (ele: any, i: number) => {


                        await listpermissionforfolderid(access_token, ele.id)
                          .then(async (eleres: any) => {
                            console.log(eleres);
                            eleres = eleres.data.permissions.findIndex(
                              (err: any) => err.role === "reader"
                            );
                             console.log("no reader found",eleres);
                              await createpermissionforfolderid(
                                access_token,
                                ele.id,
                                this.state.user.email
                              )
                                .then((res: any) => {
                                  console.log(res);
                                })
                                .catch((err: any) => {
                                  console.log(err);
                                });
                            

                            if (i == arrayofaccountfolders.length - 1) {
                              this.setState({ users: users, loading: false });
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                          });


                          // await deletepermissionforfolderid(access_token,ele.id,"04233318790921179738").then((dleerr)=>{
                          //   console.log(dleerr);
                            
                          // }).catch((err)=>{
                          //   console.log(err);
                            
                          // })
                      }
                    );
                  }
                })
                .catch((err) => {
                  console.log(err);
                  if(err.code==="ERR_BAD_REQUEST"){
                    window.location.href="/login"

                  }
                });
            })
            .catch((err) => {
              console.log(err);
              this.setState({ view: "error" });
            });
        } else {
          console.log(data);
          this.setState({ view: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ view: "error" });
      });
  }

  render() {
    var propuser = this.props.user;
    var { view, user, updateBtn, ischecked, users, loading } = this.state;

    if (view === "ok") {
      console.log(user.status);

      return (
        <div>
          <Navbar
            user={{
              email: propuser.email,
              accesstype: propuser.accesstype,
              pages: pages,
              settings: settings,
            }}
          ></Navbar>

          <Stack alignItems={"center"} sx={{ mt: "1rem" }} gap={1}>
            {" "}
            <Avatar
              alt={user.name}
              src={user.image}
              variant="rounded"
              sx={{ width: "100px", height: "100px" }}
              imgProps={{
                referrerPolicy: "no-referrer",
              }}
            />
            <div>
              <h1>Dealer Name: {user.name}</h1>
              <h4>Email: {user.email}</h4>
              <h4>Approved by: {user.approvedby}</h4>
              <h4>Status: {user.status}</h4>

              <div style={{ alignItems: "center", display: "flex" }}>
                <Typography style={{ margin: "10px" }}>
                  Pending approval
                </Typography>
                <Switch
                  disabled={updateBtn}
                  checked={user.status.includes("Approved") ? true : false}
                  // onChange={this.handleChange}
                  onClick={this.handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography style={{ margin: "10px" }}>
                  Approved successfully
                </Typography>
              </div>
            </div>
            <Stack direction={"row"} gap={1}>
              <Stack spacing={3} sx={{ width: 500 }}>
                <Autocomplete
                  size="small"
                  multiple
                  freeSolo
                  options={[]}
                  value={user.devices}
                  renderInput={(params) => (
                    <TextField {...params} label="Accounts under dealer" />
                  )}
                  onChange={(e, value) => {
                    var user = this.state.user;
                    user.devices = value;
                    this.setState({ user });
                  }}
                />
              </Stack>
              <Button
                variant="outlined"
                disabled={updateBtn}
                onClick={() => {
                  this.setState({ updateBtn: true });
                  console.log(JSON.stringify(user.devices));
                  
                  updateDealers(user.devices, this.props.params.uid)
                    .then(({ data }) => {
                      if (data.status === 200) {
                        this.setState({ updateBtn: false });
                        alert("Updated");
                        window.location.reload()
                      } else {
                        console.log(data);
                        this.setState({ updateBtn: false, view: "error" });
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      this.setState({ updateBtn: false, view: "error" });
                    });
                }}
              >
                {updateBtn ? "Updating..." : "Update"}
              </Button>
            </Stack>
            {/* here is the accounts list */}
          </Stack>

          <div style={{ marginTop: "10px" }}>
            <TableGrid
              rows={users}
              columns={[
                {
                  field: "image",
                  headerName: "Image",
                  renderCell: (params: any) => {
                    return (
                      <Avatar
                        alt={params.row.name}
                        src={params.row.image}
                        variant="rounded"
                        imgProps={{
                          referrerPolicy: "no-referrer",
                        }}
                      />
                    );
                  },
                  flex: 1,
                },
                {
                  field: "name",
                  headerName: "Name",
                  renderCell: (params: any) => {
                    const text = JSON.stringify(params.row);
                    const base64 = btoa(text).toString();

                    return (
                      <Link to={"/users/" + params.row.uid + "/" + base64}>
                        {params.row.name}
                      </Link>
                    );
                  },
                  flex: 1,
                },
                {
                  field: "email",
                  headerName: "Email",
                  flex: 1,
                },
                {
                  field: "phone",
                  headerName: "mobile",
                  flex: 1,
                },
                {
                  field: "address",
                  headerName: "address",
                  flex: 1,
                },
              ]}
              isLoading={loading}
            />
          </div>
        </div>
      );
    }

    if (view === "loading") return <div>Loading...</div>;
    if (view === "error") return <div>Error</div>;
  }
}

export default class Dealer extends Component {
  render() {
    return (
      <AuthConsumer>
        {(props: any) => {
          console.log(props);
          return <Dealercomponent {...this.props} {...props} />;
        }}
      </AuthConsumer>
    );
  }
}
