import axios from "axios";
import { ApiUrl } from "../../../../../configs/ApiUrl";

export function updateDevices(devices: string[], uid: string) {
  let config = {
    method: "get",
    url:
      ApiUrl +
      `?route=admin&action=updateDevices&uid=${uid}&devices=${JSON.stringify(
        devices
      )}`,
    headers: {},
  };
  return axios(config);
}
export function getDevices(uid: string) {
  let config = {
    method: "get",
    url: ApiUrl + "?route=admin&action=getDevices&uid=" + uid,
    headers: {},
  };
  return axios(config);
}
