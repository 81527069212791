import { Stack, Typography } from "@mui/material";
import { DataGrid, GridCallbackDetails } from "@mui/x-data-grid";
import React, { Component } from "react";
type props = Readonly<{
  rows: any[];
  columns: any[];
  isLoading: boolean;
}>;
export default class TableGrid extends Component {
  props: props;
  constructor(props: props) {
    super(props);
    this.props = props;
    this.state = {};
  }
  render() {
    var { rows, columns, isLoading } = this.props;
    return (
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        // pageSize={10}
        // rowsPerPageOptions={[10]}
        // disableSelectionOnClick
        autoHeight={true}
        // pagination
        // paginationMode="client"
        // onPageChange={onPageChange}
        // page={currentPage}
        loading={isLoading}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Typography>No Users</Typography>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Typography>No Results</Typography>
            </Stack>
          ),
        }}
      />
    );
  }
}
