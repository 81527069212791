import React, { Component } from "react";

import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Home from "./routes/Home/Main";
import Notfound from "./routes/404/404";
import Auth from "./authentication/Auth/Auth";
import { withParams } from "./authentication/params";
import Login from "./routes/login/Login";
import Users from "./routes/users/Users";
import User from "./routes/users/routes/user/User";
import Logs from "./routes/users/routes/user/routes/logs/Logs";
import Management from "./routes/Management/Management";
import Dealers from "./routes/Dealers/Dealers";
import Dealer from "./routes/Dealers/Dealer";
var AuthWithParams = withParams(Auth);
export default class App extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<AuthWithParams />}>
          {/* <Route path="/" element={<Outlet></Outlet>}> */}
          <Route
            index
            element={
              <Navigate to="management" />
              // <Home />
            }
          ></Route>
          <Route path="users" element={<Users />}></Route>
          <Route path="management" element={<Management />}></Route>
          <Route path="dealers" element={<Dealers />}></Route>
          <Route path="dealer/:uid/:base64" element={<Dealer />}></Route>
          <Route path="users/:uid/:base64" element={<User />}></Route>
          <Route path="users/:uid/:base64/logs" element={<Logs />}></Route>
        </Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="*" element={<Notfound />}></Route>
      </Routes>
    );
  }
}
