import { rejects } from "assert";
import axios from "axios";
import { resolve } from "path";


export function getDriveLogs({
  access_token,
  uid,
  folderid
}: {
  uid: string;
  access_token: string;
  folderid:any
}) {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://www.googleapis.com/drive/v3/files?q='${folderid}'+in+parents&fields=files(id,name,createdTime,mimeType)`,
      headers: {
        Authorization: "Bearer " + access_token,
      },
    };
    axios(config)
      .then(({ data }) => {
        var files = data.files;
        console.log(files);
        console.log(uid);
        var folder = files.find(
          (file: any) =>
            file.mimeType === "application/vnd.google-apps.folder" &&
            file.name === "log_" + uid
        );

        if (folder) {
          var folderId = folder.id;
          let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents&fields=files(id,name,createdTime,mimeType)`,
            headers: {
              Authorization: "Bearer " + access_token,
            },
          };
          axios(config)
            .then(({ data: userFilesData }) => {
              var userFiles = userFilesData.files;
              if (
                userFiles &&
                Array.isArray(userFiles) &&
                userFiles.length > 0
              ) {
                resolve(userFiles);
              } else {
                reject("No logs found");
              }
            })
            .catch(({ response }) => {
              console.log(response.data.error);
              reject(response.data.error);
            });
        } else {
          reject("No logs found");
        }
      })
      .catch(({ response }) => {
        console.log(response.data.error);
        reject(response.data.error);
      });
  });
}
export function getLog({
  access_token,
  fileId,
}: {
  access_token: string;
  fileId: string;
}) {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
      headers: {
        Authorization: "Bearer " + access_token,
      },
    };
    axios(config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        var error = response.data.error;
        console.log(error);
        reject(error);
      });
  });
}

export async function getSharedWithMeFolders(accessToken:string) {
  try {
    const response = await axios.get('https://www.googleapis.com/drive/v3/files', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
      params: {
        q: 'sharedWithMe=true',
        fields: 'files(id,name)'
      }
    });

    return response;
  } catch (error) {
    console.error('Error retrieving shared with me folders:', error);
    throw error;
  }
};

export async function getlogsdata(access_token:string,folderId:string){
  return new Promise((resolve,reject)=>{
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents&fields=files(id,name,createdTime,mimeType)`,
        headers: {
          Authorization: "Bearer " + access_token,
        },
      };
      axios(config)
        .then(({ data: userFilesData }) => {
          var userFiles = userFilesData.files;
          if (
            userFiles &&
            Array.isArray(userFiles) &&
            userFiles.length > 0
          ) {
            resolve(userFiles);
          } else {
            reject("No logs found");
          }
        })
        .catch(({ response }) => {
          console.log(response.data.error);
          reject(response.data.error);
        });
} catch (error) {
console.error('Error retrieving shared with me folders:', error);
reject(error);
}
  })

}