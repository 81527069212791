import { useNavigate, useParams } from "react-router-dom";

export const withParams = (WrappedComponent: any) => (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = Object.fromEntries(urlSearchParams.entries());
  return (
    <WrappedComponent
      {...props}
      params={params}
      searchParams={searchParams}
      navigate={navigate}
    />
  );
};
