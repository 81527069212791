import axios from "axios";
import { ApiUrl } from "../configs/ApiUrl";

export function updateDealers(devices: string[], uid: string) {
  let config = {
    method: "get",
    url:
      ApiUrl +
      `?route=admin&action=updateDelears&uid=${uid}&devices=${JSON.stringify(
        devices
      )}`,
    headers: {},
  };
  return axios(config);
}

export function updateDealersstatus(approvedby:string,status:string, uid: string) {
  let config = {
    method: "get",
    url:
      ApiUrl +
      `?route=admin&action=updateDealerstatus&uid=${uid}&approvedby=${approvedby}&status=${status}`,
    headers: {},
  };
  return axios(config);
}

export function getDealers(uid: string) {
  let config = {
    method: "get",
    url: ApiUrl + "?route=admin&action=getAllDealers&uid=" + uid,
    headers: {},
  };
  return axios(config);
}

export function getalladminfolders(token:string) {
  let config = {
    method: "get",
    url: "https://www.googleapis.com/drive/v3/files?pageSize=100&q='1ehYxtYNVK8ukEL844FFNq_FTiz9HbJ3s' in parents",
    headers: { 
      'Authorization': 'Bearer '+token
    }
  };
  return axios(config);
}

export function listpermissionforfolderid(token:string,id:string) {
  let config = {
    method: "get",
    url: `https://www.googleapis.com/drive/v3/files/${id}/permissions`,
    headers: { 
      'Authorization': 'Bearer '+token
    }
  };
  return axios(config);
}

export function createpermissionforfolderid(token:string,id:string,mail:string) {
  let config = {
    method: "post",
    url: `https://www.googleapis.com/drive/v3/files/${id}/permissions`,
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+token
    },
    data: JSON.stringify({
      "emailAddress": mail,
      "type": "user",
      "role": "reader",
      "permissionDetails": [
        {
          "permissionType": "member",
          "inheritedFrom": id,
          "role": "reader",
          "inherited": true
        }
      ]
    })
  };
  return axios(config);
}
export function deletepermissionforfolderid(token:string,id:string,permissionid:string) {
  let config = {
    method: "delete",
    url: `https://www.googleapis.com/drive/v3/files/${id}/permissions/${permissionid}`,
    headers: { 
      'Authorization': 'Bearer '+token
    }
  };
  return axios(config);
}

