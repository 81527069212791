import React, { Component } from "react";
import { AuthConsumer } from "../../../../../../authentication/context";
import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import Map from "./components/Map/Map";
import { getDriveLogs, getLog, getSharedWithMeFolders, getlogsdata } from "./controllers/drive.controller";
type props = {
  user: { email: string; accesstype: string; access_token: string };
  params: { uid: string };
  navigate: (path: string) => void;
};
// const latitudes = [40.712776, 51.507351, 35.689487, 41.902782, 22.319303];
// const longitudes = [-74.005974, -0.127758, 139.691706, 12.496366, 114.169361];

class LogsComponent extends Component {
  props: props;
  state: Readonly<{
    log_files: {
      value: string;
      values: { name: string; value: string }[];
    };
    logs: {
      latitude: number;
      longitude: number;
      spray: number;
    }[];
    linearLoading: boolean;
    showMap: boolean;
    view: "loading" | "ok" | "error" | "No logs found";
  }>;
  constructor(props: props) {
    super(props);
    this.props = props;
    this.state = {
      view: "loading",
      log_files: {
        value: "",
        values: [],
      },
      linearLoading: false,
      showMap: false,
      logs: [],
    };
  }
  componentDidMount(): void {
    var {
      user: { access_token ,accesstype},
      params: { uid },
    } = this.props;

    console.log(uid,accesstype);

    if(accesstype == "dealers"){
    console.log("dealer logs access ",uid,accesstype);

    getSharedWithMeFolders(access_token).then((shres)=>{
      console.log(shres);
      var sharedadat  = shres.data.files.find((eres:any)=>eres.name=="log_"+uid?eres.id:null)
      console.log(sharedadat);      
      getlogsdata(access_token,sharedadat.id).then((logsData: any) => {
        var log_files = this.state.log_files;
        log_files.values = logsData.map((log: any) => {
          return { name: log.name, value: log.id };
        });
        this.setState({ log_files, view: "ok" });
      })
      .catch((e) => {
        console.log(e);
        if (e.status === "UNAUTHENTICATED") {
          window.location.href = "/login";
        } else {
          this.setState({ view: e });
        }
      });
    }).catch((err)=>{
      console.log(err);
      
    })


    }else{
      getDriveLogs({ uid, access_token, folderid:"1ehYxtYNVK8ukEL844FFNq_FTiz9HbJ3s"})
      .then((logsData: any) => {
        var log_files = this.state.log_files;
        log_files.values = logsData.map((log: any) => {
          return { name: log.name, value: log.id };
        });
        this.setState({ log_files, view: "ok" });
      })
      .catch((e) => {
        console.log(e);

        if (e.status === "UNAUTHENTICATED") {
          window.location.href = "/login";
        } else {
          this.setState({ view: e });
        }
      });
    }
    
   
  }
  getCoordinates() {
    var {
      user: { access_token },
      params: { uid },
    } = this.props;
    var { log_files } = this.state;
    console.log(log_files);
    
    getLog({ access_token, fileId: log_files.value })
      .then((data: any) => {
        var [firstLine, ...lines]: [firstLine: string] = data.split("\n");
        var logs = lines
          .map((line: string) => {
            var [time, latitude, longitude, spray] = line.split(" ");
            return { time, latitude, longitude, spray };
          })
          .map(({ time, latitude, longitude, spray }) => {
            return {
              time,
              latitude: parseFloat(latitude),
              longitude: parseFloat(longitude),
              spray: parseInt(spray),
            };
          })
          .filter(({ latitude, longitude }, index, arr) => {
            return (
              arr.findIndex(
                (item) =>
                  item.latitude === latitude && item.longitude === longitude
              ) === index
            );
          });
        console.log(logs);
        this.setState({
          logs,
          linearLoading: false,
          showMap: true,
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.status === "UNAUTHENTICATED") {
          
          window.location.href = "/login";
        } else {
          this.setState({ view: e });
        }
      });
  }
  render() {
    var { view, log_files, linearLoading, showMap, logs } = this.state;
    var { user } = this.props;
    if (view === "loading") {
      return <div>Fetching logs...</div>;
    } else if (view === "ok") {
      return (
        <>
          <Stack gap={1}>
            <FormControl fullWidth>
              <InputLabel>Logs</InputLabel>
              <Select
                value={log_files.value}
                label="Age"
                onChange={(e) => {
                  var value = e.target.value;
                  var log_files = this.state.log_files;
                  log_files.value = value;
                  if (value !== "") {
                    this.getCoordinates();
                  }
                  this.setState({
                    log_files,
                    linearLoading: true,
                    showMap: false,
                  });
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {log_files.values.map(({ name, value }, index) => (
                  <MenuItem key={index} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {log_files.value !== "" && linearLoading && <LinearProgress />}
            {log_files.value !== "" && showMap && <Map logs={logs} />}
          </Stack>
        </>
      );
    } else if (view === "No logs found") {
      return <div>No logs found</div>;
    } else {
      return <div>Something went wrong</div>;
    }
  }
}
export default class Logs extends Component {
  render() {
    return (
      <AuthConsumer>
        {(props: any) => {
          console.log(props);
          return <LogsComponent {...this.props} {...props} />;
        }}
      </AuthConsumer>
    );
  }
}
