import React, { Component } from 'react'
import { AuthConsumer } from '../../authentication/context';
import { getAlldealers } from '../../controllers/dealers.controller';
import { AppBar, Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import TableGrid from '../users/components/TableGrid';
import Navbar from '../../NavBar/Navbar';

type props = { user: { email: string ,accesstype:string} };

const pages = ['Home'];
const settings = ['Logout'];

class DealersComponents extends Component {
    props: props;
    state: Readonly<{
      view: "loading" | "ok" | "error";
      loading: boolean;
      users: {}[];
    }>;
    constructor(props: props) {
      super(props);
      this.props = props;
      this.state = {
        view: "ok",
        loading: true,
        users: [],
      };
    }


    componentDidMount(): void {
        var {
          user: { email ,accesstype},
        } = this.props;
    
        console.log(email,accesstype);
        
        getAlldealers(email,accesstype)
          .then(({ data }) => {
              var [cols, ...data] = data.users;
              console.log(data);
              var users = data.map((user: any, id: number) => {
                var [uid, name, email, image ,status, approvedby] = user;
                return { id, uid, name, email, image ,status, approvedby };
              });
              console.log(users);
              this.setState({ users, loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ view: "error" });
          });
      }

  render() {
    var { view, loading, users} = this.state;
    var {user} = this.props

    if (view === "ok") {
      return (
        <div>

            <Navbar user={{
                  email: user.email,
                  accesstype: user.accesstype,
                  pages: pages,
                  settings: settings
              }} ></Navbar>

        <TableGrid
          rows={users}
          columns={[
            {
              field: "image",
              headerName: "Image",
              renderCell: (params: any) => {
                return (
                  <Avatar
                    alt={params.row.name}
                    src={params.row.image}
                    variant="rounded"
                    imgProps={{
                      referrerPolicy: "no-referrer",
                    }}
                  />
                );
              },
              flex: 1,
            },
            {
              field: "name",
              headerName: "Name",
              renderCell: (params: any) => {
                const text = JSON.stringify(params.row);
                const base64 = btoa(text).toString();

                return (
                  <Link to={"/dealer/" + params.row.uid + "/" + base64}>
                    {params.row.name}
                  </Link>
                );
              },
              flex: 1,
            },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
            },
            {
              field: "status",
              headerName: "status",
              flex: 1,
            },
            {
              field: "approvedby",
              headerName: "approvedby",
              flex: 1,
            },
          ]}
          isLoading={loading}
        />

        </div>
      );
    } else {
      return <div>Something went wrong</div>;
    }
  }
}

export default class Dealers extends Component {
    render() {
      return (
        <AuthConsumer>
          {(props: any) => {
            console.log(props);
            return <DealersComponents {...this.props} {...props} />;
          }}
        </AuthConsumer>
      );
    }
  }