import React, { Component } from "react";
import { MapContainer, TileLayer, Marker, Polyline } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./Map.css";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import redMarkerIcon from "./red-icon.png";
const defaultIcon = L.icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});
const redIcon = L.icon({
  iconUrl: redMarkerIcon,
  iconRetinaUrl: redMarkerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});
const tileLayerUrl = "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}";

type props = {
  logs: {
    latitude: number;
    longitude: number;
    spray: number;
  }[];
};

class MapComponent extends Component {
  props: props;
  constructor(props: props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    var { logs } = this.props;
    const latitudes = logs.map((log) => log.latitude);
    const longitudes = logs.map((log) => log.longitude);
    const sprayDefault = 1600;

    logs = logs.reduce((accumulator:any, currentValue:any) => {
      console.log(accumulator,currentValue);
      
      if (!accumulator.find((errs:any)=>errs.time===currentValue.time)) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);

    const redObjects = logs
      .filter((item) => item.spray <= sprayDefault)
      .map((item) => ({ latitude: item.latitude, longitude: item.longitude }));
    const green = logs
      .filter((item) => item.spray > sprayDefault)
      .map((item) => ({ latitude: item.latitude, longitude: item.longitude }));
    const redPlots: any[][] = redObjects.map((item) => [
      item.latitude,
      item.longitude,
    ]);
    const greenPlots: any[][] = green.map((item) => [
      item.latitude,
      item.longitude,
    ]);

    // var polyLines = latitudes.map((lat: any, i: number) => [
    //   lat,
    //   longitudes[i],
    // ]);
    return (
      <div className="map-container">
        <MapContainer center={[latitudes[0], longitudes[0]]} zoom={20}>
          <TileLayer url={tileLayerUrl} />
          <Marker position={[latitudes[0], longitudes[0]]} icon={defaultIcon} title="starting point"/>
          <Marker
            position={[
              latitudes[latitudes.length - 1],
              longitudes[longitudes.length - 1],
            ]}
            icon={redIcon}
            title="ending point"
          />
          <Polyline positions={greenPlots} color="green" />
          <Polyline positions={redPlots} color="red" />
        </MapContainer>
      </div>
    );
  }
}

export default MapComponent;
