import React, { Component } from "react";
import { AuthConsumer } from "../../../../authentication/context";
import { Autocomplete, Avatar, Button, Stack, TextField } from "@mui/material";
import { UserInterface } from "../../../../interfaces/user.interface";
import { getDevices, updateDevices } from "./controller/user.controller";
type props = {
  user: { email: string };
  params: { uid: string; base64: string };
  navigate: any;
};
class UserComponent extends Component {
  props: props;
  state: Readonly<{
    user: UserInterface;
    view: "loading" | "ok" | "error";
    updateBtn: boolean;
  }>;
  constructor(props: props) {
    super(props);
    this.props = props;
    this.state = {
      updateBtn: false,
      view: "loading",
      user: {
        access_token: "",
        uid: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        image: "",
        id: "",
        devices: [],
      },
    };
  }
  componentDidMount(): void {
    var {
      user: { email },
      params: { uid, base64 },
    } = this.props;
    var decoded = atob(base64).toString();
    var objUser = JSON.parse(decoded);
    console.log(objUser);
    getDevices(uid)
      .then(({ data }) => {
        if (data.status === 200) {
          var devices = data.devices.filter((device: any) => device !== "");
          console.log(devices);
          this.setState({ user: { ...objUser, devices }, view: "ok" });
        } else {
          console.log(data);
          this.setState({ view: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ view: "error" });
      });
  }
  render() {
    var { view, user, updateBtn } = this.state;

    if (view === "ok") {
      return (
        <Stack alignItems={"center"} sx={{ mt: "1rem" }} gap={1}>
          {" "}
          <Avatar
            alt={user.name}
            src={user.image}
            variant="rounded"
            sx={{ width: "100px", height: "100px" }}
            imgProps={{
              referrerPolicy: "no-referrer",
            }}
          />
          <div>
            <h1>Name: {user.name}</h1>
            <h4>Email: {user.email}</h4>
            <h4>Phone: {user.phone}</h4>
            <h4>Address: {user.address}</h4>
          </div>
          <Stack direction={"row"} gap={1}>
            <Stack spacing={3} sx={{ width: 500 }}>
              <Autocomplete
                size="small"
                multiple
                freeSolo
                options={[]}
                value={user.devices}
                renderInput={(params) => (
                  <TextField {...params} label="Devices" />
                )}
                onChange={(e, value) => {
                  var user = this.state.user;
                  user.devices = value;
                  this.setState({ user });
                }}
              />
            </Stack>
            <Button
              variant="outlined"
              disabled={updateBtn}
              onClick={() => {
                this.setState({ updateBtn: true });
                updateDevices(user.devices, user.uid)
                  .then(({ data }) => {
                    if (data.status === 200) {
                      this.setState({ updateBtn: false });
                      alert("Updated");
                    } else {
                      console.log(data);
                      this.setState({ updateBtn: false, view: "error" });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    this.setState({ updateBtn: false, view: "error" });
                  });
              }}
            >
              {updateBtn ? "Updating..." : "Update"}
            </Button>
          </Stack>
          <Button
            variant="outlined"
            onClick={() => {
              this.props.navigate(window.location.pathname + "/logs");
            }}
          >
            View logs
          </Button>
        </Stack>
      );
    }
    if (view === "loading") return <div>Loading...</div>;
    if (view === "error") return <div>Error</div>;
  }
}
export default class User extends Component {
  render() {
    return (
      <AuthConsumer>
        {(props: any) => {
          console.log(props);
          return <UserComponent {...this.props} {...props} />;
        }}
      </AuthConsumer>
    );
  }
}
