import { Link } from "@mui/material";
import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import { AuthConsumer, AuthProvider } from "../context";
import { isAdmin } from "../../controllers/google.controller";
type props = {
  navigate: any;
};
class AuthComponent extends Component {
  state: Readonly<{
    view: "ok" | "unauthorized" | "loading" | "poll_error";
    user: any;
  }>;
  props: props;
  constructor(props: props) {
    super(props);
    this.props = props;
    this.state = {
      view: "loading",
      user: {},
    };
  }
  componentDidMount() {
    var stringUser =
      process.env.NODE_ENV === "development"
        ? window.localStorage.getItem("tk")
        : window.sessionStorage.getItem("tk");
    if (stringUser) {
      var objUser = JSON.parse(stringUser);
      console.log(objUser);
      
      var { email } = objUser;
      console.log(email);
      
      isAdmin({"email":email})
        .then((res) => {
          console.log(res);

            if(res.data.isAdmin===true){
              var user = { ...objUser };
              user["accesstype"]="admin"
              this.setState({ view: "ok", user });
            }else{
              if(res.data.user[4].includes("pending...")){
            this.setState({ view: "unauthorized" });                
              }else{
                objUser["accesstype"]="dealers"
                var user = { ...objUser };
              this.setState({ view: "ok", user });
              }

            }
            
            
           
        })
        .catch((err) => {
          console.log(err);
          this.setState({ view: "poll_error" });
        });
    } else {
      window.location.href = "/login";
    }
  }

  render() {
    var { view, user } = this.state;
    if (view === "ok") {
      return (
        <AuthProvider value={{ ...this.props, user }}>
          <Outlet></Outlet>
        </AuthProvider>
      );
    } else if (view === "loading") {
      return <>Loading...</>;
    } else if (view === "unauthorized") {
      return <>You are not allowed to use this website.</>;
    } else {
      return <>Something went wrong</>;
    }
  }
}

export default class Auth extends Component {
  render() {
    return (
      <AuthConsumer>
        {(props: any) => {
          console.log(props);
          return <AuthComponent {...this.props} {...props} />;
        }}
      </AuthConsumer>
    );
  }
}
