import { Component } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import HandshakeIcon from '@mui/icons-material/Handshake';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupIcon from '@mui/icons-material/Group';
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

const drawerWidth = 240;


interface managementcomponent{
    loadstate:string
}

export default class Management extends Component<{},managementcomponent> {


     constructor(props: {}) {
      super(props);
      this.state = {
        loadstate: "none",
      };
    }

    componentDidMount=()=>{
        var {loadstate }= this.state
        var stringUser =
      process.env.NODE_ENV === "development"
        ? window.localStorage.getItem("tk")
        : window.sessionStorage.getItem("tk");
    if (stringUser) {
        var objUser = JSON.parse(stringUser);
        console.log(objUser);
        if(objUser.accesstype==="dealers"){
        window.location.href = "/users"
        }else{
            this.setState({loadstate:"block"})

        }
        
    }else{
        window.location.href = "/login"
    }
    }
    
  openlinktopahe = (index: any, text: any) => {
    if ("Dealers" == text) {
      window.location.href = "/dealers";
    } else {
      window.location.href = "/users";
    }
    console.log(index, text);
  };

  container = window !== undefined ? () => window.document.body : undefined;

  render() {
    var {loadstate }= this.state

    return (
      <div style={{display:loadstate}}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Agrikraft admin dashboard
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              <Toolbar />
              <Divider />
              <List>
                {["Dealers", "users"].map((text, index) => (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        this.openlinktopahe(index, text);
                      }}
                    >
                      <ListItemIcon>
                        {index % 2 === 0 ? <HandshakeIcon /> : <GroupIcon />}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            <Typography paragraph>Manage all Dealers and users</Typography>
            <Typography paragraph>Application link down here </Typography>
            <a href="https://play.google.com/store/apps/details?id=com.drone.agrikraft" target="_blank" rel="noopener noreferrer">Android app</a>
          </Box>
        </Box>
      </div>
    );
  }
}
