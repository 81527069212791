import React, { Component } from "react";
import { AuthConsumer } from "../../authentication/context";
import { getAllUsers } from "../../controllers/users.controller";
import TableGrid from "./components/TableGrid";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
type props = { user: { email: string ,accesstype:string} };
class UsersComponent extends Component {
  props: props;
  state: Readonly<{
    view: "loading" | "ok" | "error";
    loading: boolean;
    users: {}[];
  }>;
  constructor(props: props) {
    super(props);
    this.props = props;
    this.state = {
      view: "ok",
      loading: true,
      users: [],
    };
  }
  componentDidMount(): void {
    var {
      user: { email ,accesstype},
    } = this.props;

    console.log(email,accesstype);
    
    
    getAllUsers(email,accesstype)
      .then(({ data }) => {
        if(accesstype == "dealers"){
          data = data.data.users;
          console.log(data);
          var users = data.map((user: any, id: number) => {
  
            var finaldata = {
              "uid": user.uid,
              "name": user.name,
              "email": user.mail,
              "phone": user.mobile,
              "address": user.address||"",
              "image": user.photo||"",
              "id":id
            }
  
            return finaldata;
          });
          console.log(users);
        this.setState({ users, loading: false });
        }else{
          var [cols, ...data] = data.users;
          console.log(data);
          var users = data.map((user: any, id: number) => {
            var [uid, name, email, phone, address, image] = user;
            return { id, uid, name, email, phone, address, image };
          });
          console.log(users);
          this.setState({ users, loading: false });
  
        }
       
        
      })
      .catch((err) => {
        console.log(err);
        this.setState({ view: "error" });
      });
  }
  render() {
    var { view, loading, users } = this.state;
    if (view === "ok") {
      return (
        <TableGrid
          rows={users}
          columns={[
            {
              field: "image",
              headerName: "Image",
              renderCell: (params: any) => {
                return (
                  <Avatar
                    alt={params.row.name}
                    src={params.row.image}
                    variant="rounded"
                    imgProps={{
                      referrerPolicy: "no-referrer",
                    }}
                  />
                );
              },
              flex: 1,
            },
            {
              field: "name",
              headerName: "Name",
              renderCell: (params: any) => {
                const text = JSON.stringify(params.row);
                const base64 = btoa(text).toString();

                return (
                  <Link to={"/users/" + params.row.uid + "/" + base64}>
                    {params.row.name}
                  </Link>
                );
              },
              flex: 1,
            },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
            },
            {
              field: "phone",
              headerName: "Phone",
              flex: 1,
            },
            {
              field: "address",
              headerName: "Address",
              flex: 1,
            },
          ]}
          isLoading={loading}
        />
      );
    } else {
      return <div>Something went wrong</div>;
    }
  }
}
export default class Users extends Component {
  render() {
    return (
      <AuthConsumer>
        {(props: any) => {
          console.log(props);
          return <UsersComponent {...this.props} {...props} />;
        }}
      </AuthConsumer>
    );
  }
}
