import axios from "axios";
import { ApiUrl } from "../configs/ApiUrl";

export function getAllUsers(email: string,accesstype:String) {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ApiUrl + "?route=admin&action=getAllUsers&email=" + email+"&accesstype="+accesstype,
    headers: {},
  };
  return axios(config);
}
