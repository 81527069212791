import {
  AppBar,
  Container,
  Toolbar,
  Tooltip,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Avatar,
} from "@mui/material";
import { Component } from "react";
import AdbIcon from "@mui/icons-material/Adb";
import MenuIcon from "@mui/icons-material/Menu";

type props = {
  user: {
    email: string;
    accesstype: string;
    pages: string[];
    settings: string[];
  };
};

export default class Navbar extends Component {
  props: props;
  state: Readonly<{
    view: "loading" | "ok" | "error";
    loading: boolean;
    users: {}[];
    anchorElNav: any;
    anchorElUser: any;
  }>;
  constructor(props: props) {
    super(props);
    this.props = props;
    this.state = {
      view: "ok",
      loading: true,
      users: [],
      anchorElNav: null,
      anchorElUser: null,
    };
  }
  handleOpenNavMenu = (event: any) => {
    this.setState({ anchorElNav: event.currentTarget });
  };

  handleOpenUserMenu = (event: any) => {
    console.log("surya111", event.currentTarget);

    this.setState({ anchorElUser: event.currentTarget });
  };

  handleCloseNavMenu = (item: any) => {
    console.log(item);

    if (item === "Dealers") {
      window.location.href = "/dealers";
    } else if (item === "Home") {
      window.location.href = "/";
    }
    this.setState({ anchorElNav: null });
  };

  handleCloseUserMenu = (item: any) => {
    console.log(item);

    if (item == "Logout") {
      console.log("surya111", item);
      localStorage.clear();
      window.location.href = "/login";
    }

    this.setState({ anchorElUser: null });
  };

  render() {
    var { anchorElNav, anchorElUser } = this.state;
    var { user } = this.props;

    return (
      <div>
        <AppBar position="static" style={{ marginBottom: "7px" }}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                AgriKraft
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={this.handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={() => {
                    this.handleCloseNavMenu(null);
                  }}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {user.pages.map((page) => (
                    <MenuItem
                      key={page}
                      onClick={() => {
                        this.handleCloseNavMenu(page);
                      }}
                    >
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Agrikraft
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {user.pages.map((page) => (
                  <Button
                    key={page}
                    onClick={() => {
                      this.handleCloseNavMenu(page);
                    }}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={this.handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src="https://img.icons8.com/fluency/96/000000/user-male-circle.png"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={() => {
                    this.handleCloseUserMenu(null);
                  }}
                >
                  {user.settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => {
                        this.handleCloseUserMenu(setting);
                      }}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
    );
  }
}
